$font-path: "./webfonts/" !default

@font-face
  font-family: "ProximaNova-Bold"
  src: url("#{$font-path}2F1FE6_1_0.eot")
  src: url("#{$font-path}2F1FE6_1_0.eot?#iefix") format("embedded-opentype"),url("#{$font-path}2F1FE6_1_0.woff2") format("woff2"),url("#{$font-path}2F1FE6_1_0.woff") format("woff"),url("#{$font-path}2F1FE6_1_0.ttf") format("truetype")

@font-face
    font-family: "ProximaNova-Semibold"
    src: url("#{$font-path}2F1FE6_6_0.eot")
    src: url("#{$font-path}2F1FE6_6_0.eot?#iefix") format("embedded-opentype"),url("#{$font-path}2F1FE6_6_0.woff2") format("woff2"),url("#{$font-path}2F1FE6_6_0.woff") format("woff"),url("#{$font-path}2F1FE6_6_0.ttf") format("truetype")

@font-face
  font-family: "ProximaNova-Regular"
  src: url("#{$font-path}2F1FE6_5_0.eot")
  src: url("#{$font-path}2F1FE6_5_0.eot?#iefix") format("embedded-opentype"),url("#{$font-path}2F1FE6_5_0.woff2") format("woff2"),url("#{$font-path}2F1FE6_5_0.woff") format("woff"),url("#{$font-path}2F1FE6_5_0.ttf") format("truetype")

@font-face
  font-family: "ProximaNova-Medium"
  src: url("#{$font-path}2F1FE6_4_0.eot")
  src: url("#{$font-path}2F1FE6_4_0.eot?#iefix") format("embedded-opentype"),url("#{$font-path}2F1FE6_4_0.woff2") format("woff2"),url("#{$font-path}2F1FE6_4_0.woff") format("woff"),url("#{$font-path}2F1FE6_4_0.ttf") format("truetype")

@font-face
  font-family: "ProximaNova-Light"
  src: url("#{$font-path}2F1FE6_3_0.eot")
  src: url("#{$font-path}2F1FE6_3_0.eot?#iefix") format("embedded-opentype"),url("#{$font-path}2F1FE6_3_0.woff2") format("woff2"),url("#{$font-path}2F1FE6_3_0.woff") format("woff"),url("#{$font-path}2F1FE6_3_0.ttf") format("truetype")

@font-face
  font-family: "ProximaNova-Thin"
  src: url("#{$font-path}2F1FE6_7_0.eot")
  src: url("#{$font-path}2F1FE6_7_0.eot?#iefix") format("embedded-opentype"),url("#{$font-path}2F1FE6_7_0.woff2") format("woff2"),url("#{$font-path}2F1FE6_7_0.woff") format("woff"),url("#{$font-path}2F1FE6_7_0.ttf") format("truetype")

//$font-black: "ProximaNova-Black"
//$font-extraBold: "ProximaNova-ExtraBold"
$font-bold: "ProximaNova-Bold"
$font-semiBold: "ProximaNova-SemiBold"
$font-regular: "ProximaNova-Regular"
$font-medium: "ProximaNova-Medium"
$font-light: "ProximaNova-Light"
$font-thin: "ProximaNovaT-Thin"
