@import "./utils/all"

/**
 * Default layout variables
 */
$header-height: 64px !default
$header-background: hsl(0, 0%, 48%) !default
$header-color: white !default
$footer-height: 0px !default
$footer-background: hsl(0, 0%, 48%) !default
$footer-color: white !default
$max-width-body: 600px !default
$main-border: 1px solid var(--silver) !default
$modal-background-background-color: rgba(41, 41, 41, 0.4) !default


/**
 * Colors:
 * based on UX guidelines: https://netatmo.atlassian.net/wiki/spaces/UD/pages/410911007/Color
 * $primary is set independently in each project
 */
$black: #181818 !default
$grey-darker: #292929 !default
$grey-dark: #545454 !default
$grey-medium: #7f7f7f !default
$grey: #C3C3C3 !default
$grey-light: #D4D4D4 !default
$grey-lighter: #DDDDDD !default
$grey-lighter-cold: #ECECF1 !default
$white-ter-cold: #F5F5F8 !default
$white-ter: #FDFDFD !default
$bright-silver: #f4f4f4 !default
$white: #FFFFFF !default
$danger: #DF5353 !default
$warning: #FFCA28 !default
$success: #53DF80 !default
$switch-color: $success !default
$html-background-color: $white !default
$netatmo-orange: #ff8500 !default
$secondary: $netatmo-orange !default
/**
 * Common variable, define global style of all websettings (based on UX team guidelines)
 * @type sass variables
 */
$text: $black !default
$body-line-height: 1.2 !default
$body-background-color: $white !default
$body-size: 16px !default
$size-7: .875rem !default
$header-height: 4rem !default//64px

$loader-color: black !default
$image-gutter: .5rem !default

$space-icon: 1rem !default

/**
 * Form Variables
 */
$control-height: 1.75em !default // based on UX guidelines
$control-line-height: 1.375rem !default // (22px)
$button-max-width: 345px !default
$label-weight: inherit !default
$input-focus-border-color: white !default
$input-focus-box-shadow-color: transparent !default

$title-size: 1rem !default
$subtitle-size: .875rem !default
$subtitle-color: $grey !default

/**
 * Title Variables
 * https://bulma.io/documentation/elements/title/#variables
 */
$title-line-height: 1.5 !default

/**
 * Button Variables
 * https://bulma.io/documentation/elements/button/#variables
 */
$button-border-radius: 8px !default
$buttoncircle: 50px !default
$loading-color: $grey-dark !default
// $button-background-color: transparent
// $button-border-color: transparent

/**
 * Panel Component
 * https://bulma.io/documentation/components/panel/#variables
 */
$panel-heading-background-color: white !default
$panel-heading-color: $black !default
$panel-heading-line-height: calculateRem(22px) !default
$panel-heading-radius: 0 !default
$panel-heading-padding: calculateRem(32px) 1rem calculateRem(16px) 1rem !default
$panel-heading-size: 1rem !default
$panel-block-padding-vertical: 1rem !default
$panel-block-padding-horizontal: 1rem !default
$panel-block-padding: $panel-block-padding-vertical $panel-block-padding-horizontal !default
$panel-block-small-height: 3rem !default
$panel-block-default-height: 4rem !default
$panel-block-large-height: 5rem !default
$panel-margin: 0 !default
$panel-item-border: 1px solid #ECECEC !default
$panel-radius: 0px !default

$panel-footer-padding: 18px !default

$lay-link-chevron-color: $grey-light !default

/**
  * Modal
  * https://bulma.io/documentation/components/modal/
  */
$modal-card-foot-radius: 0 !default

/**
  * Triple Dot Menu
  *
  */
$dropdown-menu-min-width: 18rem !default


/**
  * Image
  * https://bulma.io/documentation/elements/image/#variables
  */
$dimensions: 16 24 40 56 64 96 128 !default

/**
  * Page loader
  */
$page-loader-size: 35px !default
$page-loader-secondary-color: rgba(0,0,0,.1) !default
$page-loader-primary-color: black !default
$page-loader-thickness: 3px !default

/**
  * Text settings
  */
$text-spacing: 18px !default
$text-size: .90rem !default
$text-line-height: 1.3rem !default
$text-heading-size: 1.125rem !default
$section-title-size: 1.125rem !default
$subtext-spacing: 8px !default

$page-title-font-size: 1.5rem !default

/**
  * Heading
  */
$heading-spacing: $text-spacing !default
$heading-subtext-spacing: $subtext-spacing !default

/**
  * Icon
  */
$icon-x-large-size: 6rem !default
$icon-m-large-size: 3.5rem !default
/**
  * Badge
  */
$badge-text-color: white !default
$badge-bg-color: black !default
$badge-gutter: .5rem !default
$badge-width-small: 17px !default
$badge-width-default: 30px !default
$badge-width-medium: 45px !default
$badge-width-large: 60px !default
$badge-font-size-medium: 1.5rem !default
$badge-font-size-large: 2rem !default
$badge-padding-fluid: 15px !default
$badge-text-top: 1px !default

/**
  * Slider
  */
$slider-height: 6px !default
$slider-handle-height: 20px !default

// Removes border
$tabs-border-bottom-style: none

/**
  * Bottom Drawer
  */
$bottom-drawer-height: 18rem !default
$drawer-transition-speed: .3s !default



/**
  * Input Text and Password
  */
$input-label-color: $black !default


/**
  * Typography Defaults
  */
$family-primary: sans-serif  !default
$family-bold: sans-serif !default
$family-semiBold: sans-serif !default
$family-medium: sans-serif !default
$family-regular: sans-serif !default
$family-light: sans-serif !default
$title-weight: inherit !default
$font-size-heading: 1.25rem !default
$font-size-input-number: 1.125rem !default
