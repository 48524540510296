.text-grey-100 { color: var(--grey-100); }
.text-grey-90 { color: var(--grey-90); }
.text-grey-80 { color: var(--grey-80); }
.text-grey-70 { color: var(--grey-70); }
.text-grey-50 { color: var(--grey-50); }
.text-grey-30 { color: var(--grey-30); }
.text-grey-20 { color: var(--grey-20); }
.text-grey-10 { color: var(--grey-10); }
.text-grey-0 { color: var(--grey-0); }

.text-primary             { color: var(--primary); }
.text-blue                { color: var(--blue); }
.text-blue-10             { color: var(--blue-10); }
.text-blue-15             { color: var(--blue-15); }
.text-netatmo-orange      { color: var(--netatmo-orange); }
.text-green-confirmation  { color: var(--green-confirmation); }
.text-red-alert           { color: var(--red-alert); }
.text-orange-alert        { color: var(--orange-alert); }
.text-yellow              { color: var(--yellow); }
.text-sky-blue            { color: var(--sky-blue); }
.text-cornflower-blue     { color: var(--cornflower-blue); }
.text-arctic-blue         { color: var(--arctic-blue); }
.text-see-green           { color: var(--see-green); }
.text-olive-green         { color: var(--olive-green); }
.text-eco-green           { color: var(--eco-green); }
.text-salmon-pink         { color: var(--salmon-pink); }
.text-ruby-pink           { color: var(--ruby-pink); }
.text-amnesty-purple      { color: var(--amnesty-purple); }
.text-coral-pink          { color: var(--coral-pink); }

.text-grey-hover-100:hover { color: var(--grey-100); }
.text-grey-hover-90:hover { color: var(--grey-90); }
.text-grey-hover-80:hover { color: var(--grey-80); }
.text-grey-hover-70:hover { color: var(--grey-70); }
.text-grey-hover-50:hover { color: var(--grey-50); }
.text-grey-hover-30:hover { color: var(--grey-30); }
.text-grey-hover-20:hover { color: var(--grey-20); }
.text-grey-hover-10:hover { color: var(--grey-10); }
.text-grey-hover-0:hover { color: var(--grey-0); }

.text-10 { font-size: 0.625rem; }
.text-12 { font-size: 0.75rem;  }
.text-14 { font-size: 0.875rem; }
.text-16 { font-size: 1rem;     }
.text-18 { font-size: 1.125rem; }
.text-20 { font-size: 1.25rem;  }
.text-22 { font-size: 1.375rem; }
.text-24 { font-size: 1.5rem;   }
.text-32 { font-size: 2rem;     }
.text-40 { font-size: 2.5rem;   }