:root {
  // Fonts
  --font-family-primary: var(--font-family-regular);
  --font-family-bold: ProximaNova-Bold, sans-serif;
  --font-family-semiBold: ProximaNova-SemiBold, sans-serif;
  --font-family-medium: ProximaNova-medium, sans-serif;
  --font-family-regular: ProximaNova-regular, sans-serif;
  --font-family-light: ProximaNova-light, sans-serif;


  // Header
  --header-height: 4rem;
  --background-header: var(--white);
  --header-color: var(--black-grey);

  --modal-radius: 6px;
}
