$roboto-path: './webfonts/' !default

@font-face
    font-family: 'Roboto-Bold'
    src: url("#{$roboto-path}Roboto-Black.ttf") format("truetype")

@font-face
    font-family: 'Roboto-SemiBold'
    src: url("#{$roboto-path}Roboto-Bold.ttf") format("truetype")

@font-face
    font-family: 'Roboto-Medium'
    src: url("#{$roboto-path}Roboto-Medium.ttf") format("truetype")

@font-face
    font-family: 'Roboto-Regular'
    src: url("#{$roboto-path}Roboto-Regular.ttf") format("truetype")

@font-face
    font-family: 'Roboto-Light'
    src: url("#{$roboto-path}Roboto-Light.ttf") format("truetype")

@font-face
    font-family: 'Roboto-Thin'
    src: url("#{$roboto-path}Roboto-Thin.ttf") format("truetype")

$font-bold: "Roboto-Bold"
$font-semiBold: "Roboto-SemiBold"
$font-medium: 'Roboto-Medium'
$font-regular: "Roboto-Regular"
$font-light: "Roboto-Light"
$font-thin: "Roboto-Thin"
