@import 'utils/functions';

@mixin icon-size($size, $unit) {
  width: size($size, $unit);
  height: size($size, $unit);
  font-size: size($size, $unit);
}

// Todo ajouter params position of margin
@mixin spacing($size) {
  & > :not(:last-child) {
    margin-bottom: spacing($size, px) + px;
  }
}

@mixin for-mobile-portrait {
  @media (max-width: 600px) { @content; }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}

@mixin for-desktop-only {
  @media (min-width: 1200px) and (max-width: 1799px) { @content; }
}

@mixin for-medium-desktop-up {
  @media (min-width: 1400px) { @content; }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

@mixin loader {
  animation: spin 500ms infinite linear;
  border: 2px solid var(--light-grey);
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}
