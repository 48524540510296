html {
  box-sizing: border-box;
}

*,:after,:before {
  box-sizing: inherit;
}

body {
  font-family: var(--font-family-primary);
  color: var(--black-grey);
  background-color: var(--white);
}

figure, body, html, p, li, ul, textarea, ol, pre, iframe, input {
  margin: 0;
  padding: 0;
}

iframe {
  border: none;
}

input[type="text"], input[type="number"] {
  background-color: var(--white);
  color: var(--grey-90);
}


.columns {
  margin-left: .75rem;
  margin-right: .75rem;
  margin-top: .75rem;

  &:last-child {
    margin-bottom: 0.75rem;
  }

  &.negative-margin{
    margin-left: -.75rem;
    margin-right: -.75rem;
    margin-top: -.75rem;

    &:last-child{
      margin-bottom: -0.75rem;
    }
  }

  &.is-gapless {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.is-mobile {
    display: flex;
  }

  &.is-vcentered {
    align-items: center;
  }
  .is-mobile >.column.is-narrow {
    flex: none;
  }

  &.is-multiline {
    flex-wrap: wrap;
  }
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}

.columns.is-gapless>.column {
  margin: 0;
  padding: 0!important;
}

.columns.is-mobile>.column {

  &.is-3 {
    flex: none;
    width: 25%;
  }

  &.is-5 {
    flex: none;
    width: 41%;
  }

  &.is-6 {
    flex: none;
    width: 50%;
  }

  &.is-7 {
    flex: none;
    width: 58%;
  }

  &.is-10 {
    flex: none;
    width: 83.33%;
  }

}

@keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}

.is-loading {
  // Handles inline text in buttons/everything else
  background: transparent;
  color: transparent !important;
  pointer-events: none;

  & > * {
    background: transparent;
    color: transparent !important;
    pointer-events: none;
  }

  &:after {
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
    position: absolute !important;
    -webkit-animation: spin .5s linear infinite;
    animation: spin .5s linear infinite;
    border-radius: 290486px;
    border-color: #fff #fff transparent transparent;
    border-style: solid;
    border-width: 2px;
    content: "";
    display: block;
    height: 1em;
    position: relative;
    width: 1em;
  }
}

.home-image {
  height: auto;
  overflow: hidden;
}

.is-hidden{
  display: none !important
}

textarea:focus, input:focus{
  outline: none;
  border: 0px solid;
}
