@import "variables/color.scss";

html {
    font-size: 16px;
}

body {
    font-family: "ProximaNova-Regular", sans-serif;
}

html,
body {
  background-color: var(--body-background-color);
}
