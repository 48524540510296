/* You can add global styles to this file, and also import other style files */
@import "generic/all";
@import "helpers/all";
@import 'variables/css-variables';
@import "common-helpers.sass";
@import "./app/sass/utils/all";

// common global classes
@import './_common-styles.scss';

@import '_angular-material-theme.scss';

:root {
  --secondary: var(--netatmo-orange);
  --modal-radius: 12px;
}

html, body {
  height: 100%;
  scroll-behavior: smooth;

  color: var(--grey-90);
}

input {
  font-family: inherit;
}

b {
  font-weight: normal !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.content > router-outlet + * {
  width: 100%;
}


/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--grey) transparent;
}

app-create-building {
  .modal-container {
    overflow: visible !important;
  }
  app-modal-body {
    overflow: visible !important;
  }

}

.modal-container {
  width: 685px;
}

.healthy {
  color: var(--green-confirmation);
}

.unhealthy {
  color: var(--red-alert);
}

.fine {
  color: var(--green-confirmation);
}

.fair {
  color: var(--yellow);

}

.poor {
  color: var(--red-alert);
}

.unknown {
  color: var(--grey-50);
}
